<template>
  <div class="task-sort">
    <v-select
      label="name"
      :options="sort"
      v-model="filters.sort"
      :reduce="(item) => item.id"
      placeholder="Select sort"
      :clearable="false"
      :searchable="false"
    >
      <template #selected-option="{ name, icon, color }">
        <vs-icon :icon="icon" :color="color"></vs-icon>
        {{ name }}
      </template>
      <template #option="{ name, icon, color }">
        <vs-icon :icon="icon" :color="color"></vs-icon>
        {{ name }}
      </template>
    </v-select>
  </div>
</template>

<script>
import { mapState } from "vuex";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { TASK_SORT } from "../dicts";

export default {
  props: ["value"],
  data() {
    return {
      filters: this.value,
      sort: TASK_SORT,
    };
  },
  computed: {
    ...mapState({
      teachers: (state) => state.teachers.teachers,
      groups: (state) => state.groups.groups,
      students: (state) => state.students.students,
    }),
  },
  components: {
    vSelect,
  },
  methods: {
    handleInput(v) {
      const filters = Object.keys(v)
        .filter((k) => v[k] != null)
        .reduce((a, b) => ({ ...a, [b]: v[b] }), {});
      filters.page = 1;
      this.$emit("input", { ...filters });
    },
  },
};
</script>

<style lang="scss">
.task-sort {
  .v-select {
    min-width: 10rem;
    &:not(.vs--open) {
      .vs__dropdown-toggle {
        border: none;
        background-color: #ddd;
      }
    }
  }
}
</style>