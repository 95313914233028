<template>
  <div class="task-filters">
    <div class="field">
      <label for="">Task name</label>
      <vs-input placeholder="Name..." v-model="filters.name" />
    </div>
    <div class="field">
      <label for="">Importance</label>
      <v-select
        label="name"
        :options="importance"
        v-model="filters.importance"
        :reduce="item => item.id"
        autocomplete="on"
        placeholder="Select importance"
      >
        <template #selected-option="{ name, icon, color }">
          <vs-icon :icon="icon" :color="color"></vs-icon>
          {{ name }}
        </template>
        <template #option="{ name, icon, color }">
          <vs-icon :icon="icon" :color="color"></vs-icon>
          {{ name }}
        </template>
      </v-select>
    </div>
    <div class="field">
      <label for="">Status</label>
      <v-select
        label="name"
        :options="status"
        v-model="filters.status"
        :reduce="item => item.id"
        autocomplete="on"
        placeholder="Select status"
        multiple
      >
        <template #selected-option="{ name }">
          {{ name }}
        </template>
        <template #option="{ name }">
          {{ name }}
        </template>
      </v-select>
    </div>
    <div class="field">
      <label for="">Teacher</label>
      <v-select
        label="nickname"
        :options="teachers"
        v-model="filters.teacher_ids"
        :reduce="item => item.id"
        autocomplete="on"
        clearable
        placeholder="Select teacher"
        :closeOnSelect="true"
      >
        <template #option="{ nickname, options }">
          <vs-avatar
            :src="
              options && options.avatar
                ? `${proxy}/static/files/${options.avatar}`
                : undefined
            "
          ></vs-avatar
          >{{ nickname }}
        </template>
        <template #selected-option="{ nickname, options }">
          <vs-avatar
            size="20px"
            :src="
              options && options.avatar
                ? `${proxy}/static/files/${options.avatar}`
                : undefined
            "
          ></vs-avatar>
          {{ nickname }}
        </template>
      </v-select>
    </div>
    <div class="field">
      <label for="">Group</label>
      <v-select
        label="name"
        :options="groups"
        v-model="filters.group_id"
        :reduce="item => item.id"
        autocomplete="on"
        clearable
        placeholder="Select group"
        :closeOnSelect="true"
        menu-props="auto"
      >
        <template #option="{ color, name }">
          <vs-avatar icon="people" :color="color"></vs-avatar>
          {{ name }}
        </template>
        <template #selected-option="{ color, name }">
          <vs-avatar icon="people" :color="color" size="20px"></vs-avatar>
          {{ name }}
        </template>
      </v-select>
    </div>
    <div class="field">
      <label for="">Students</label>
      <v-select
        label="name"
        :options="studentByGroup"
        v-model="filters.student_id"
        :reduce="item => item.id"
        autocomplete="on"
        clearable
        placeholder="Select student"
        :closeOnSelect="true"
        menu-props="auto"
      >
        <template #option="{ name }">
          <vs-avatar icon="person"></vs-avatar>
          {{ name }}
        </template>
        <template #selected-option="{ name }">
          <vs-avatar icon="person" size="20px"></vs-avatar>
          {{ name }}
        </template>
      </v-select>
    </div>
    <div class="field">
      <label for="">Given by</label>
      <v-select
        label="nickname"
        :options="teachers"
        v-model="filters.owner_id"
        :reduce="item => item.id"
        autocomplete="on"
        clearable
        placeholder="Given by"
        :closeOnSelect="true"
      >
        <template #option="{ nickname, options }">
          <vs-avatar
            :src="
              options && options.avatar
                ? `${proxy}/static/files/${options.avatar}`
                : undefined
            "
          ></vs-avatar
          >{{ nickname }}
        </template>
        <template #selected-option="{ nickname, options }">
          <vs-avatar
            size="20px"
            :src="
              options && options.avatar
                ? `${proxy}/static/files/${options.avatar}`
                : undefined
            "
          ></vs-avatar>
          {{ nickname }}
        </template>
      </v-select>
      <span class="quick-filter" @click="meFilterHandler">Me</span>
    </div>
    <div class="field date-range">
      <div>Date start from</div>
      <v-date-picker v-model="filters.date_start" is-range>
        <template v-slot="{ inputValue, inputEvents }">
          <vs-input
            :value="inputValue.start"
            class="inline-datepicker"
            readonly
            v-on="inputEvents.start"
            label-placeholder="Min"
          />
          <vs-icon icon="arrow_right_alt" class="inline-datepicker"></vs-icon>
          <vs-input
            :value="inputValue.end"
            class="inline-datepicker"
            readonly
            v-on="inputEvents.end"
            label-placeholder="Max"
          />
        </template>
      </v-date-picker>
    </div>
    <div class="field date-range">
      <div>Date finish from</div>
      <v-date-picker v-model="filters.date_finish" is-range>
        <template v-slot="{ inputValue, inputEvents }">
          <vs-input
            :value="inputValue.start"
            class="inline-datepicker"
            readonly
            v-on="inputEvents.start"
            label-placeholder="Min"
          />
          <vs-icon icon="arrow_right_alt" class="inline-datepicker"></vs-icon>
          <vs-input
            :value="inputValue.end"
            class="inline-datepicker"
            readonly
            v-on="inputEvents.end"
            label-placeholder="Max"
          />
        </template>
      </v-date-picker>
    </div>
    <div class="field">
      <vs-checkbox v-model="filters.overdue">Просроченные</vs-checkbox>
    </div>
    <div class="field submit-task-filters">
      <vs-button @click="handleInput(filters)">Filter</vs-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import { TASK_IMPORTANCE, TASK_STATUS } from '../dicts'

export default {
  props: ['value'],
  data() {
    return {
      proxy: process.env.VUE_APP_PROXY,
      filters: { ...this.value },
      importance: TASK_IMPORTANCE,
      status: TASK_STATUS,
    }
  },
  computed: {
    ...mapState({
      teachers: state => state.teachers.teachers,
      groups: state => state.groups.groups,
      students: state => state.students.students,
    }),
    studentByGroup: function() {
      if (!this.filters.group_id) return this.students

      const group = this.groups.find(g => g.id == this.filters.group_id)
      if (!group) return this.students
      return group.students
    },
  },
  components: {
    vSelect,
  },
  methods: {
    handleInput(v) {
      const filters = Object.keys(v)
        .filter(k => v[k] !== false && v[k] !== null)
        .reduce((a, b) => {
          if (b.startsWith('date')) {
            // костыыыыль! но работает
            return {
              ...a,
              [`${b}_start`]: v[b].start ? v[b].start.getTime() : '',
              [`${b}_end`]: v[b].end ? v[b].end.getTime() : '',
            }
          } else {
            return { ...a, [b]: v[b] }
          }
        }, {})
      filters.page = 1
      this.$emit('input', { ...filters })
    },
    meFilterHandler: function() {
      const user = JSON.parse(window.localStorage.getItem('user'))
      const filters = { ...this.filters }
      filters.owner_id = user.id
      this.filters = filters
      setTimeout(() => {
        this.handleInput(this.filters)
      }, 10)
    },
  },
  created: async function() {
    const schoolyear_id = window.localStorage.getItem('schoolyearId')
    if (!this.groups.length) {
      await this.$store.dispatch('groups/getGroupsAction', {
        data: {
          schoolyear_id,
        },
      })
    }
    if (!this.students.length) {
      await this.$store.dispatch('students/getStudentsAction')
    }
    if (!this.teachers.length) {
      await this.$store.dispatch('teachers/getTeachersAction')
    }
  },
}
</script>

<style lang="scss">
.task-filters {
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 1rem;
  align-items: flex-start;
  gap: 0.5rem;
  .field {
    width: 100%;
    .vs-input {
      width: 100%;
    }
    .vs-input--input.normal,
    .v-select > div {
      height: 2.2rem;
    }
    &.date-range {
      & > span {
        display: flex;
      }
    }
  }
  label {
    display: none !important;
  }
  .submit-task-filters {
    position: sticky;
    bottom: 0;
  }
}
</style>
