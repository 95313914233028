var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"task-filters"},[_c('div',{staticClass:"field"},[_c('label',{attrs:{"for":""}},[_vm._v("Task name")]),_c('vs-input',{attrs:{"placeholder":"Name..."},model:{value:(_vm.filters.name),callback:function ($$v) {_vm.$set(_vm.filters, "name", $$v)},expression:"filters.name"}})],1),_c('div',{staticClass:"field"},[_c('label',{attrs:{"for":""}},[_vm._v("Importance")]),_c('v-select',{attrs:{"label":"name","options":_vm.importance,"reduce":function (item) { return item.id; },"autocomplete":"on","placeholder":"Select importance"},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var name = ref.name;
var icon = ref.icon;
var color = ref.color;
return [_c('vs-icon',{attrs:{"icon":icon,"color":color}}),_vm._v(" "+_vm._s(name)+" ")]}},{key:"option",fn:function(ref){
var name = ref.name;
var icon = ref.icon;
var color = ref.color;
return [_c('vs-icon',{attrs:{"icon":icon,"color":color}}),_vm._v(" "+_vm._s(name)+" ")]}}]),model:{value:(_vm.filters.importance),callback:function ($$v) {_vm.$set(_vm.filters, "importance", $$v)},expression:"filters.importance"}})],1),_c('div',{staticClass:"field"},[_c('label',{attrs:{"for":""}},[_vm._v("Status")]),_c('v-select',{attrs:{"label":"name","options":_vm.status,"reduce":function (item) { return item.id; },"autocomplete":"on","placeholder":"Select status","multiple":""},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var name = ref.name;
return [_vm._v(" "+_vm._s(name)+" ")]}},{key:"option",fn:function(ref){
var name = ref.name;
return [_vm._v(" "+_vm._s(name)+" ")]}}]),model:{value:(_vm.filters.status),callback:function ($$v) {_vm.$set(_vm.filters, "status", $$v)},expression:"filters.status"}})],1),_c('div',{staticClass:"field"},[_c('label',{attrs:{"for":""}},[_vm._v("Teacher")]),_c('v-select',{attrs:{"label":"nickname","options":_vm.teachers,"reduce":function (item) { return item.id; },"autocomplete":"on","clearable":"","placeholder":"Select teacher","closeOnSelect":true},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var nickname = ref.nickname;
var options = ref.options;
return [_c('vs-avatar',{attrs:{"src":options && options.avatar
              ? (_vm.proxy + "/static/files/" + (options.avatar))
              : undefined}}),_vm._v(_vm._s(nickname)+" ")]}},{key:"selected-option",fn:function(ref){
              var nickname = ref.nickname;
              var options = ref.options;
return [_c('vs-avatar',{attrs:{"size":"20px","src":options && options.avatar
              ? (_vm.proxy + "/static/files/" + (options.avatar))
              : undefined}}),_vm._v(" "+_vm._s(nickname)+" ")]}}]),model:{value:(_vm.filters.teacher_ids),callback:function ($$v) {_vm.$set(_vm.filters, "teacher_ids", $$v)},expression:"filters.teacher_ids"}})],1),_c('div',{staticClass:"field"},[_c('label',{attrs:{"for":""}},[_vm._v("Group")]),_c('v-select',{attrs:{"label":"name","options":_vm.groups,"reduce":function (item) { return item.id; },"autocomplete":"on","clearable":"","placeholder":"Select group","closeOnSelect":true,"menu-props":"auto"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
              var color = ref.color;
              var name = ref.name;
return [_c('vs-avatar',{attrs:{"icon":"people","color":color}}),_vm._v(" "+_vm._s(name)+" ")]}},{key:"selected-option",fn:function(ref){
              var color = ref.color;
              var name = ref.name;
return [_c('vs-avatar',{attrs:{"icon":"people","color":color,"size":"20px"}}),_vm._v(" "+_vm._s(name)+" ")]}}]),model:{value:(_vm.filters.group_id),callback:function ($$v) {_vm.$set(_vm.filters, "group_id", $$v)},expression:"filters.group_id"}})],1),_c('div',{staticClass:"field"},[_c('label',{attrs:{"for":""}},[_vm._v("Students")]),_c('v-select',{attrs:{"label":"name","options":_vm.studentByGroup,"reduce":function (item) { return item.id; },"autocomplete":"on","clearable":"","placeholder":"Select student","closeOnSelect":true,"menu-props":"auto"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
              var name = ref.name;
return [_c('vs-avatar',{attrs:{"icon":"person"}}),_vm._v(" "+_vm._s(name)+" ")]}},{key:"selected-option",fn:function(ref){
              var name = ref.name;
return [_c('vs-avatar',{attrs:{"icon":"person","size":"20px"}}),_vm._v(" "+_vm._s(name)+" ")]}}]),model:{value:(_vm.filters.student_id),callback:function ($$v) {_vm.$set(_vm.filters, "student_id", $$v)},expression:"filters.student_id"}})],1),_c('div',{staticClass:"field"},[_c('label',{attrs:{"for":""}},[_vm._v("Given by")]),_c('v-select',{attrs:{"label":"nickname","options":_vm.teachers,"reduce":function (item) { return item.id; },"autocomplete":"on","clearable":"","placeholder":"Given by","closeOnSelect":true},scopedSlots:_vm._u([{key:"option",fn:function(ref){
              var nickname = ref.nickname;
              var options = ref.options;
return [_c('vs-avatar',{attrs:{"src":options && options.avatar
              ? (_vm.proxy + "/static/files/" + (options.avatar))
              : undefined}}),_vm._v(_vm._s(nickname)+" ")]}},{key:"selected-option",fn:function(ref){
              var nickname = ref.nickname;
              var options = ref.options;
return [_c('vs-avatar',{attrs:{"size":"20px","src":options && options.avatar
              ? (_vm.proxy + "/static/files/" + (options.avatar))
              : undefined}}),_vm._v(" "+_vm._s(nickname)+" ")]}}]),model:{value:(_vm.filters.owner_id),callback:function ($$v) {_vm.$set(_vm.filters, "owner_id", $$v)},expression:"filters.owner_id"}}),_c('span',{staticClass:"quick-filter",on:{"click":_vm.meFilterHandler}},[_vm._v("Me")])],1),_c('div',{staticClass:"field date-range"},[_c('div',[_vm._v("Date start from")]),_c('v-date-picker',{attrs:{"is-range":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var inputValue = ref.inputValue;
              var inputEvents = ref.inputEvents;
return [_c('vs-input',_vm._g({staticClass:"inline-datepicker",attrs:{"value":inputValue.start,"readonly":"","label-placeholder":"Min"}},inputEvents.start)),_c('vs-icon',{staticClass:"inline-datepicker",attrs:{"icon":"arrow_right_alt"}}),_c('vs-input',_vm._g({staticClass:"inline-datepicker",attrs:{"value":inputValue.end,"readonly":"","label-placeholder":"Max"}},inputEvents.end))]}}]),model:{value:(_vm.filters.date_start),callback:function ($$v) {_vm.$set(_vm.filters, "date_start", $$v)},expression:"filters.date_start"}})],1),_c('div',{staticClass:"field date-range"},[_c('div',[_vm._v("Date finish from")]),_c('v-date-picker',{attrs:{"is-range":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var inputValue = ref.inputValue;
              var inputEvents = ref.inputEvents;
return [_c('vs-input',_vm._g({staticClass:"inline-datepicker",attrs:{"value":inputValue.start,"readonly":"","label-placeholder":"Min"}},inputEvents.start)),_c('vs-icon',{staticClass:"inline-datepicker",attrs:{"icon":"arrow_right_alt"}}),_c('vs-input',_vm._g({staticClass:"inline-datepicker",attrs:{"value":inputValue.end,"readonly":"","label-placeholder":"Max"}},inputEvents.end))]}}]),model:{value:(_vm.filters.date_finish),callback:function ($$v) {_vm.$set(_vm.filters, "date_finish", $$v)},expression:"filters.date_finish"}})],1),_c('div',{staticClass:"field"},[_c('vs-checkbox',{model:{value:(_vm.filters.overdue),callback:function ($$v) {_vm.$set(_vm.filters, "overdue", $$v)},expression:"filters.overdue"}},[_vm._v("Просроченные")])],1),_c('div',{staticClass:"field submit-task-filters"},[_c('vs-button',{on:{"click":function($event){return _vm.handleInput(_vm.filters)}}},[_vm._v("Filter")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }