<template>
  <div class="task-list-view">
    <div class="view-content">
      <div class="task-list-view__header">
        <h2>Tasks list</h2>
        <div class="view-manage">
          <div class="view-manage__button">
            <vs-button
              icon="add_task"
              class="status-bar__edit-button"
              type="flat"
              color="primary"
              @click="editPopupActive = true"
              v-if="$can('manage', 'add_task')"
              >Add task</vs-button
            >
          </div>
          <div>
            <task-sort v-model="filters" />
          </div>
        </div>
      </div>
      <Split>
        <SplitArea :size="30">
          <div class="panel-view">
            <div class="task-list-sort"></div>
            <div class="task-list-items">
              <div
                v-for="task in tasks"
                :key="task.id"
                @click="selectTask(task)"
              >
                <task-list-item-compact :task="task" />
              </div>
            </div>
            <vs-pagination
              :total="totalPages"
              v-model="filters.page"
              :max="7"
            ></vs-pagination>
          </div>
        </SplitArea>
        <SplitArea :size="50" class="task-selected-panel">
          <div v-if="selectedTask">
            <task-view
              :id="selectedTask.id"
              @editTask="editTaskHandler"
              @taskSaved="taskUpdateHandler"
            />
          </div>
          <div v-else class="task-list-view__no-selected">
            <vs-icon icon="travel_explore" size="3rem" color="#ccc"></vs-icon>
            No task selected
          </div>
        </SplitArea>
        <SplitArea :size="20" class="task-filter-panel">
          <h2>Filters</h2>
          <task-filters v-model="filters" />
        </SplitArea>
      </Split>
    </div>
    <div v-if="editPopupActive">
      <vs-popup
        class="form-popup teacher-tasks-popup-edit"
        classContent="task__popup-content-edit"
        :title="selectedTask ? `Edit task #${selectedTask.id}` : 'Create task'"
        :active.sync="editPopupActive"
        @close="closeTaskPopupHandler"
      >
        <task-form @taskSaved="taskUpdateHandler" :task="selectedTask" />
      </vs-popup>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import getTasks from '@/api/tasks/getTasks'
// import ListItem from "../components/tasks/list/listItem.vue";
import TaskView from '../components/tasks/taskView.vue'
import taskListItemCompact from '../components/tasks/taskListItemCompact.vue'
import TaskFilters from '../components/tasks/list/taskFilters.vue'
import TaskForm from '../components/tasks/taskForm.vue'
import TaskSort from '../components/tasks/list/taskSort.vue'

export default {
  name: 'GroupView',
  title: 'SE-Tasks list',
  data() {
    return {
      selectedTask: null,
      tasks: [],
      editPopupActive: false,
      current: 1,
      filters: {
        page: 1,
        per_page: 50,
        sort: '-created',
        status: [0, 1],
        date_start: {
          start: null,
          end: null,
        },
        date_finish: {
          start: null,
          end: null,
        },
      },
      tasksMeta: {
        total: 0,
      },
    }
  },
  components: {
    // ListItem,
    TaskView,
    taskListItemCompact,
    TaskFilters,
    TaskForm,
    TaskSort,
  },
  computed: {
    ...mapState({
      groups: state => state.groups.groups,
      teachers: state => state.teachers.teachers,
      students: state => state.students.students,
    }),
    totalPages: function() {
      return (
        Math.ceil(this.tasksMeta.total / parseInt(this.filters.per_page, 10)) ||
        1
      )
    },
  },
  created: async function() {
    await this.getTasks()
  },
  methods: {
    getTasks: async function() {
      const { filters } = this
      // const filter = Object.keys(filters)
      //   .filter((k) => filters[k] != null)
      //   .reduce((a, b) => ({ ...a, [b]: filters[b] }), {});

      const response = await getTasks.call(this, filters)

      if (response.data) {
        this.tasks = response.data
        this.tasksMeta = response.meta
      }
    },
    selectTask: function(task) {
      this.selectedTask = null
      setTimeout(() => {
        this.selectedTask = task
      }, 50)
    },
    taskUpdateHandler: async function() {
      await this.getTasks()
      if (this.selectedTask) {
        this.$store.dispatch('tasks/getSingleTask', this.selectedTask.id)
      }
      this.editPopupActive = false
    },
    editTaskHandler: function() {
      this.editPopupActive = true
    },
    closeTaskPopupHandler: function() {
      this.selectedTask = null
    },
  },
  watch: {
    filters: {
      deep: true,
      handler: async function() {
        await this.getTasks()
      },
    },
  },
}
</script>

<style lang="scss">
.task-list-view {
  .panel-view {
    background-color: #f1f1f1;
    border-radius: 15px 0 0 0;
    padding: 10px;
  }
  &__header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 1rem;
    border-bottom: 1px solid #ccc;
    padding-bottom: 6px;
    .view-manage__button {
      .vs-button {
        float: none;
      }
    }
    .view-manage {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 1rem;
    }
  }

  .task-item {
    width: 100%;
    margin: 0.5rem 0;
  }
  .task-filter-panel {
    h2 {
      margin-top: 0;
    }
  }
  .task-selected-panel {
    .task {
      margin: 1rem;
      padding: 1.5rem;
      border-radius: 10px;
      box-shadow: 0 0 10px 1px #ddd;
    }
  }
  &__no-selected {
    margin: 1rem;
    padding: 1rem;
  }
}
.con-vs-pagination {
  width: 100%;
}
.task-filter-panel {
  padding: 1rem;
}
</style>
