var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"task-sort"},[_c('v-select',{attrs:{"label":"name","options":_vm.sort,"reduce":function (item) { return item.id; },"placeholder":"Select sort","clearable":false,"searchable":false},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var name = ref.name;
var icon = ref.icon;
var color = ref.color;
return [_c('vs-icon',{attrs:{"icon":icon,"color":color}}),_vm._v(" "+_vm._s(name)+" ")]}},{key:"option",fn:function(ref){
var name = ref.name;
var icon = ref.icon;
var color = ref.color;
return [_c('vs-icon',{attrs:{"icon":icon,"color":color}}),_vm._v(" "+_vm._s(name)+" ")]}}]),model:{value:(_vm.filters.sort),callback:function ($$v) {_vm.$set(_vm.filters, "sort", $$v)},expression:"filters.sort"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }